<!--
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
    <div>
      <div v-if="loading" class="loading-spinner-container">
        <!-- Loading indicator -->
        <div class="loading-spinner"></div>
        <p>Loading...</p>
      </div>
      <div v-else>
        <!-- Content when validation is complete -->
        <h1>Welcome!</h1>
        <p>Token validation successful. Redirecting...</p>
      </div>
    </div>
  </template>


  <script>
  export default {
    data() {
      return {
        loading: true,
      };
    },
    mounted() {
      const token = this.$route.query.token;
      if (token) {
        this.$store.dispatch("auth/postOauthLogin", token)
      }
    },
  };
  </script>

<style>
.loading-spinner {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 3px solid red;
  border-top-color: #fff;
  animation: spin 1s infinite linear;
}

.loading-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

</style>
